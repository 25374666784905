import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type Carousel = [number, Dispatch<SetStateAction<number>>];

const useCarousel = (length: number, timeout = 5000): Carousel => {
    const [selected, setSelected] = useState(0);
    const [canceled, setCanceled] = useState(false);

    useEffect(() => {
        const interval = !canceled && setTimeout(() => !canceled && setSelected((selected + 1) % length), timeout);
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [selected, canceled]);

    return [
        selected,
        (index: number) => {
            setSelected(index);
            setCanceled(true);
        },
    ];
};

export default useCarousel;
