import { graphql, Link } from 'gatsby';
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { fullWidthOnVertical } from '../../constants/css/fullWidthOnVertical';
import theme from '../../constants/theme';
import { SiteContext } from '../../contexts/SiteContext';
import {
    CaseTileDetails,
    CaseTileDetails_client_Craft_Client,
    CaseTilesFragment,
} from '../../entities/operationResults';
import BodyText from '../atoms/BodyText';
import Grid from '../atoms/Grid';
import Img from '../atoms/Img';
import Title from '../atoms/Title';

export const caseTilesFragment = graphql`
    fragment CaseTilesFragment on Craft_CasesSuperTableBlockType {
        id
        case {
            ...CaseTileDetails
        }
        text
    }

    fragment CaseTileDetails on Craft_Case {
        title
        uri
        hasCasePage
        caseImage {
            ...CommonImageFragment
        }
        client {
            ... on Craft_Client {
                title
                clientLogo {
                    url
                }
            }
        }
    }
`;

interface Props {
    data: CaseTilesFragment[];
}

const CaseTiles: FC<Props> = ({ data }) => {
    const site = useContext(SiteContext);
    return (
        <>
            {data.map((tile, i) => {
                if (!tile) {
                    return null;
                }
                // const image = tile.image && tile.image[0];
                const { id, text } = tile;
                const caseDetails = (tile.case && tile.case[0]) as CaseTileDetails;
                const alignImage = i % 2 === 0 ? 'top' : 'bottom';

                if (!caseDetails || !text) {
                    return null;
                }

                let item = <CaseTile alignImage={alignImage} caseDetails={caseDetails} text={text} key={id || i} />;

                if (caseDetails && caseDetails.uri && caseDetails.hasCasePage) {
                    item = (
                        <StyledLink key={id || i} to={site ? `/${site}/${caseDetails.uri}/` : `/${caseDetails.uri}/`}>
                            {item}
                        </StyledLink>
                    );
                }

                return item;
            })}
        </>
    );
};

interface AlignmentProps {
    alignImage: 'bottom' | 'top';
}

interface CaseTileProps extends AlignmentProps {
    text: string;
    caseDetails: CaseTileDetails;
}

const CaseTile: FC<CaseTileProps> = ({ alignImage, caseDetails, text }) => {
    const client = caseDetails?.client?.[0] as CaseTileDetails_client_Craft_Client;
    return (
        <Grid marginRight={3}>
            {caseDetails.caseImage && (
                <ImageCell alignImage={alignImage}>
                    {(() => {
                        const image = caseDetails.caseImage && caseDetails.caseImage[0];
                        return image ? (
                            <Image
                                alignImage={alignImage}
                                srcSet={`
                                        ${image.url300} 500w,
                                        ${image.url500} 1000w,
                                        ${image.url800} 1500w,
                                        ${image.url1500} 2500w
                                    `}
                                alt={image.title || undefined}
                                src={image.url800 || undefined}
                                ratio={parseInt(image.height || '0', 10) / parseInt(image.width || '0', 10)}
                            />
                        ) : null;
                    })()}
                </ImageCell>
            )}
            <ContentCell alignImage={alignImage}>
                {client?.clientLogo?.[0]?.url ? (
                    <Logo alt={caseDetails.title} src={client.clientLogo[0]?.url} />
                ) : (
                    <StyledTitle>{caseDetails.title}</StyledTitle>
                )}
                <Subtitle>{text}</Subtitle>
            </ContentCell>
        </Grid>
    );
};

const StyledLink = styled(Link)`
    /* all: inherit; */
    color: inherit;
    text-decoration: inherit;
    cursor: pointer;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    > div {
        height: 100%;
    }
`;

const Logo = styled.img`
    height: 3rem;
    margin-bottom: 2.5rem;

    @media (${theme.mediaQueries.vertical}) {
        max-height: 3rem;
        max-width: 87vw;
    }
`;

const ContentCell = styled.div<AlignmentProps>`
    @media (${theme.mediaQueries.vertical}) {
        display: flex;
        flex-direction: column;
        margin-top: 5rem;
        align-self: flex-start;
        align-items: flex-start;
    }
    @media (${theme.mediaQueries.horizontal}) {
        grid-column: 1 / 10;
        ${({ alignImage }) =>
            alignImage === 'top'
                ? `
            grid-row: 21 / -1;
        `
                : `
            grid-row: 10  / 10;
            align-self: flex-end;
        `}
    }
    @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
        grid-column: 2 / 7;
        grid-row: 5 / -5;
        align-self: center;
        height: unset;
    }
`;

const ImageCell = styled.div<{ alignImage: 'bottom' | 'top' }>`
    @media (${theme.mediaQueries.vertical}) {
        display: flex;
        flex-direction: column;
    }

    @media (${theme.mediaQueries.horizontal}) {
        grid-column: 1 / 10;
        ${({ alignImage }) =>
            alignImage === 'top'
                ? `
            grid-row: 5 / 20;
        `
                : `
            grid-row: 12 / 27;
        `}
    }

    @media (max-width: ${theme.mediaQueries.width.xxl}), (max-height: ${theme.mediaQueries.height.xxl}) {
        grid-column: 1 / 10;
    }
    @media (max-width: ${theme.mediaQueries.width.xl}), (max-height: ${theme.mediaQueries.height.xl}) {
        grid-column: 1 / 11;
    }
    @media (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
        grid-column: 1 / 12;
    }
    @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
        grid-column: 9 / 18;
        grid-row: 5 / -5;
    }
`;

const Image = styled(Img)<AlignmentProps>`
    ${fullWidthOnVertical};
    object-fit: contain;
    width: 100%;
    height: 100%;

    picture > img,
    picture > source {
        object-fit: contain;
        object-position: ${({ alignImage }) => (alignImage === 'bottom' ? 'bottom left' : 'left')};
        @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
            object-position: center;
        }
    }
`;

const StyledTitle = styled(Title)`
    font-weight: bold;
`;

const Subtitle = styled(BodyText)`
    max-width: 72rem;
    margin: 0;
    font-weight: 300;
`;

export default CaseTiles;
