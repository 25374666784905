// a general purpose component for 'sliding' between a set of images.
// preloads images and does a transition between them once the active index changes.

import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import Img, { ImgProps } from '../atoms/Img';

interface TransitionType {
    visible: CSSProperties;
    invisible: CSSProperties;
}

type TransitionsType = { [k in string]: TransitionType };

export const Transitions: TransitionsType = {
    zoom: {
        visible: {
            opacity: 1,
            transition: 'opacity .3s .2s, transform .3s .2s',
            transform: 'scale(1) translateZ(0)',
            transformOrigin: 'center center',
        },
        invisible: {
            opacity: 0,
            transition: 'opacity .3s, transform .3s',
            transform: 'scale(0.95) translateZ(0)',
            transformOrigin: 'center center',
        },
    },
};

interface Props {
    images: Array<Partial<ImgProps>>;

    selected: number;

    ratio: number;

    // pass additional css to specify custom transitions
    transition?: TransitionType;
}

const Slideshow: FC<Props> = ({ images, ratio, selected, transition = Transitions.zoom }) => (
    <Container ratio={ratio}>
        {images.map(({ ...props }, i) => (
            <SlideshowImg
                key={i}
                style={i === selected ? transition.visible : transition.invisible}
                {...(props as ImgProps)}
            />
        ))}
    </Container>
);

const Container = styled.div<{ ratio: number }>`
    position: relative;
    width: 100%;
    height: 100%;

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ${({ ratio }) => Math.round(ratio * 100)}%;
    }
`;

const SlideshowImg = styled(Img)<ImgProps>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    img {
        object-fit: contain;
    }
`;

export default Slideshow;
