import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import Grid from '../atoms/Grid';

interface Props {
    top: ReactNode;
    bottom: ReactNode;
}

const IntroBlock: FC<Props> = ({ top, bottom }) => (
    <IntroGrid marginRight={3}>
        <TopCell>{top}</TopCell>
        <BottomCell>{bottom}</BottomCell>
    </IntroGrid>
);

export default IntroBlock;

const IntroGrid = styled(Grid)`
    @media screen and (${theme.mediaQueries.vertical}) {
        grid-template-rows: auto;
    }
`;

const TopCell = styled.div`
    grid-row: 14;
    grid-column: 1 / 15;
    align-self: center;
    @media screen and (min-height: ${theme.mediaQueries.height.s}) and (min-width: ${theme.mediaQueries.width.s}) {
        grid-column: 1 / 11;
    }
    @media screen and (min-height: ${theme.mediaQueries.height.l}) and (min-width: ${theme.mediaQueries.width.l}) {
        grid-column: 1 / 9;
    }
`;
const BottomCell = styled.div`
    grid-row: 16;
    grid-column: 8 / 20;
    align-self: center;

    @media screen and (min-height: ${theme.mediaQueries.height.s}) and (min-width: ${theme.mediaQueries.width.s}) {
        grid-column: 8 / 16;
    }
    @media screen and (min-height: ${theme.mediaQueries.height.l}) and (min-width: ${theme.mediaQueries.width.l}) {
        grid-column: 7 / 14;
    }
`;
