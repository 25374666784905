import { graphql } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import { IntermittentInformationFragment, IntroFragment } from '../../entities/operationResults';
import { UnreachableCaseError } from '../../entities/UnreachableCaseError';
import ContentSection from '../molecules/ContentSection';
import BodyText from '../atoms/BodyText';
import Title from '../atoms/Title';
import IntroBlock from './IntroBlock';
import CmsContent from '../atoms/CmsContent';

export const introFragment = graphql`
    fragment IntroFragment on Craft_IntroSuperTableBlockType {
        text {
            content
        }
        subText {
            content
        }
    }
`;

interface Props {
    data: IntroFragment | IntermittentInformationFragment;
    variant: 'solution' | 'home' | 'technique' | 'aboutUs';
}

const Intro: FC<Props> = ({ data: { text, subText }, variant }) => {
    if (!(text && subText)) {
        return null;
    }

    switch (variant) {
        case 'aboutUs':
        case 'technique':
            return (
                <ContentSection>
                    {text && text.content && <CmsContent html={text.content} paragraphWrapper={Title} />}
                    {subText && subText.content && <CmsContent html={subText.content} />}
                </ContentSection>
            );
        case 'home':
            return text && text.content && subText && subText.content ? (
                <IntroBlock
                    top={<CmsContent html={text.content} paragraphWrapper={StyledBodyText} />}
                    bottom={<CmsContent html={subText.content} paragraphWrapper={Title} />}
                />
            ) : null;
        case 'solution':
            return text && text.content && subText && subText.content ? (
                <IntroBlock
                    top={<CmsContent html={text.content} paragraphWrapper={Title} />}
                    bottom={<CmsContent html={subText.content} paragraphWrapper={StyledBodyText} />}
                />
            ) : null;
        default:
            throw new UnreachableCaseError(variant);
    }
};

const StyledBodyText = styled(BodyText)`
    font-weight: 300;
`;

export default Intro;
